import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Dear TuringTrader Member,`}</p>
    <p>{`As we embrace the holiday season and the year draws to a close, it’s a good time to reflect on the intricate dance between emotions and the stock market. So, grab a cup of cocoa, settle into your favorite armchair, and follow me through some thoughts about the ever-evolving world of finance.`}</p>
    <h2>{`Big-Tech Momentum - Are We Missing Out?`}</h2>
    <p>{`Investors sometimes wonder if the TuringTrader algorithms are missing out on major momentum stocks, particularly the giants in the tech realm. Let’s delve into the last two years:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/309c39abd147b9b38e26deb402d49c5f/21b4d/magnificent-7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrUlEQVQoz32T226cMBRF+f8/6nsrVWofkqYvVSYlyQwBhosx4Au+sCq7nSpJ02xpC/uIs/FlUcxSUpYlVVWhtSaE8MIxRpL2fc/j53XnNxYzYZ1G2YVVLxRCjFxfX1P+LHPgW0phrxWiR5qeSfdIM2RbaymsMSi1Yoz525ie/zM7xD0ymxEXHKmUwp23OOcoQoivVhP/NL8dfJGPW1on+Qu5D7TRFHVTY9WEGFpmKZ5Fe95WRFmBmBqmsWcWFZNoOJ+fUOtCUbcnPn38wM3NZ75/+8IyVKzzwMOPK0Rzy24avOoIdkWJR87nA1VzzzCM1HVLU1U01SNDc8KoNW05UPcdwyw51Y9cXX2lvLujaTvKw4H7u1vSxfX9E2XzQCdXlDLIoaNpe6ZRMA0DepZoYym89/lsvPPIpac8HehEy2oFLkQejg3H+sg4tRyPJ7q2Y5Uj1rp8AJuPxH1nmjWr0r8DX6Ox+S3f2KIFq5FIKRnHEWM3nPco4wiZz5c4WWsoEnspNAVcHHzITvXEllIaMS0Z6FQL3r/oSeNt2y7YhHf5yxDnv8P/887z+UW/ADvaVUlFwKUKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "magnificent 7",
            "title": "magnificent 7",
            "src": "/static/309c39abd147b9b38e26deb402d49c5f/c1b63/magnificent-7.png",
            "srcSet": ["/static/309c39abd147b9b38e26deb402d49c5f/5a46d/magnificent-7.png 300w", "/static/309c39abd147b9b38e26deb402d49c5f/0a47e/magnificent-7.png 600w", "/static/309c39abd147b9b38e26deb402d49c5f/c1b63/magnificent-7.png 1200w", "/static/309c39abd147b9b38e26deb402d49c5f/21b4d/magnificent-7.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`All these stocks did better in 2023, but worse in 2022.`}</li>
      <li parentName="ul">{`Each one of them appears to have a higher Beta than the S&P 500.`}</li>
      <li parentName="ul">{`META stands out with little volatility and continuously outperforming the market. But over the past two years, it’s been an overall wash.`}</li>
      <li parentName="ul">{`NVDA impresses, but its high volatility would disqualify it in terms of ‘dependable’ returns.`}</li>
    </ul>
    <p>{`This highlights the delicate balance between risk and return reinforcing the notion that the loudest voice does not always provide the best guidance.`}</p>
    <h2>{`The Problem with Story Stocks`}</h2>
    <p>{`When it comes to influential voices, many of these companies have charismatic leaders armed with powerful megaphones to amplify their messages. Elon Musk is a prime example, and I recently came across this thought-provoking article of an author `}<a parentName="p" {...{
        "href": "https://www.fool.com/investing/2023/12/01/buying-tesla-taught-me-a-costly-lesson/"
      }}>{`sharing his experiences investing in Tesla stock`}</a>{`. He describes his personal investment journey, his decision-making process, and valuable lessons learned in a relatable manner.`}</p>
    <p>{`The author was initially attracted by Elon Musk’s visionary leadership and the belief in the company’s disruptive qualities. Even though his concerns about Musk’s actions and leadership grew over time, he doubled down on his investment, nonetheless. After ultimately selling all shares, the author candidly admits that, despite his fortunate outcome, his gains were the result of a lucky bet rather than a well-researched strategic investment decision. “Sometimes, you get lucky that way. Most of the time, you don’t.” In other words, emotional investment decisions are better suited for wealthy people who can afford this level of risk but typically not appropriate to become rich. We discuss the differences between the `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/basics/investment-scenarios/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-december-2023"
      }}>{`major investment archetypes`}</a>{` in more detail in our book.`}</p>
    <p>{`But this story is not about Tesla. It illustrates how in the short term, the stock market is often driven by emotions from news, but in the long term by economic factors. With technical analysis we adopt a different perspective excluding aspects like the quality of a company’s products or the charisma of its CEO. Relying on systematic, data-driven approaches helps mitigate emotional biases, fostering a disciplined and solvent investment strategy. In finance, emotions can be the undoing of sound decision-making. But committing to a systematic approach provides a reliable anchor while navigating the ever-evolving markets. That’s exactly why we created TuringTrader. We are excited to continue this journey with you and hope our platform will streamline your investing.`}</p>
    <p>{`As investors grapple with concerns about missing out on opportunities we want to emphasize `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_Maynard_Keynes"
      }}>{`John Maynard Keynes`}</a>{`' wisdom “The markets can remain irrational longer than you can remain solvent.” This unpredictability is precisely why we prefer technical analysis over discretionary trading and why we try not to second-guess our algorithms.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It is an honor to have you on our site. If you like what we do, support our growth by `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/company/referral-program/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-december-2023"
      }}>{`sharing with your friends`}</a>{` how TuringTrader adds value for your investing.`}</p>
    <p>{`Wishing you a joyous holiday season, stay tuned for further updates!`}<br />{`
All the best`}<br /></p>
    <br />
    <br />
Birgit<br />
--<br />
Birgit Bertram<br />
Co-Founder of TuringTrader.com

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      